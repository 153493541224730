import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { useConceptLabelFormatter } from 'reducers/entityLabelFormatter';
import { campaignAclSelectorFactory } from 'selectors/campaign';
import { conceptColorFormatterSelector } from 'selectors/entities';
import { isFeedbackCampaignSelector } from 'selectors/survey';
import {
  archiveChannel,
  defaultSortField,
  defaultSortOrder,
  deleteChannel,
  fetchChannels,
  focusChannelSelector,
  loadingChannelsSelector,
  setFocusChannelId,
} from 'store/monitor/channelsSlice';

import {
  enrichFieldProps,
  useCampaignFormFields,
  useUrlForm,
} from 'components/customer/campaign/useCampaignFields';
import Link from 'components/ui/Link';
import ManagementList from 'components/ui/ManagementList';
import ManagementListRowFactory from 'components/ui/ManagementList/ManagementListRowFactory';
import { AnalyticsAwareButton, ButtonAccent } from 'components/ui/button';
import { PageLayout } from 'components/ui/layout/Page';
import DeleteModal from 'components/ui/modal/DeleteModal';
import QrCodeModal from 'components/ui/modal/QrCodeModal';
import {
  ChannelNameCell,
  LabelCell,
} from 'components/ui/table/cells/ReactTableCell';
import { ActivityIndicatorCell } from 'components/ui/table/cells/dotCells';

import config from 'config';
import { logEvent } from 'utils/analytics';
import { useMemoizedFactorySelector } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import ChannelPane from './ChannelPane';

const formatFeedbackAppRoute = (item) =>
  item ? `${config.PREVIEW_URL}${item?.app_route}` : null;

const rowActionsFactory = (toggleQrCodeModal) => [
  {
    help: t({ id: 'display-qrcode' }),
    icon: 'qrcode',
    onClick: toggleQrCodeModal,
    isCopyToClipboard: false,
  },
  {
    help: t({ id: 'copy-application-link-to-clipboard' }),
    icon: 'copy',
    onClick: null,
    isCopyToClipboard: true,
    getCopyableText: formatFeedbackAppRoute,
  },
  {
    help: t({ id: 'open-in-new-tab' }),
    icon: 'external',
    onClick: (item) => window.open(formatFeedbackAppRoute(item), '_blank'),
    isCopyToClipboard: false,
  },
];

const emptyChannelListContentFactory = (createCampaign) => {
  function EmptyListActions() {
    return (
      <div style={{ textAlign: 'justify' }}>
        <Trans id="channel-list.no-channel-message" />
        <br />
        <br />
        <Trans>
          <Link primary base onClick={createCampaign}>
            Create a channel
          </Link>{' '}
          to start sharing your campaign.
        </Trans>
      </div>
    );
  }

  EmptyListActions.propTypes = {};
  return EmptyListActions;
};

function CampaignChannelListPage() {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [toDeleteChannel, setToDeleteChannel] = useState(null);
  const [toArchiveChannel, setToArchiveChannel] = useState(null);
  const [deletedChannelId, setDeletedChannelId] = useState(null);
  const [archivedChannelInfo, setArchivedChannelInfo] = useState(null);
  const [qrCodeModalChannel, setQrCodeModalChannel] = useState(null);

  const conceptColorFormatterBase = useSelector(conceptColorFormatterSelector);
  const conceptColorFormatter = useCallback(
    (aChannel) => conceptColorFormatterBase(aChannel?.concept?.id),
    [conceptColorFormatterBase]
  );
  const conceptLabelFormatter = useConceptLabelFormatter();
  const channelsAreLoading = useSelector(loadingChannelsSelector);
  const isFeedbackCampaign = useSelector(
    isFeedbackCampaignSelector(campaignId)
  );
  const acl = useSelector(campaignAclSelectorFactory(campaignId));
  const focusChannel = useMemoizedFactorySelector(
    focusChannelSelector,
    campaignId
  );
  const { urlFormFields: fields } = useCampaignFormFields(
    { useUrlForm },
    false
  );

  const toggleQrCodeModal = useCallback(
    (item) => setQrCodeModalChannel(item || null),
    []
  );
  const rowActions = useMemo(
    () => rowActionsFactory(toggleQrCodeModal),
    [toggleQrCodeModal]
  );
  const onOpenCreateModal = useCallback(() => navigate('create'), []);
  const onCloseDeleteModal = useCallback(() => setToDeleteChannel(null), []);
  const onCloseArchiveModal = useCallback(() => setToArchiveChannel(null), []);
  const onQrCodeModalClose = useCallback(() => setQrCodeModalChannel(null), []);
  const onValidateDelete = useCallback(() => {
    dispatch(deleteChannel({ campaignId, channelId: toDeleteChannel.id })).then(
      (result) => {
        if (deleteChannel.fulfilled.match(result)) {
          setDeletedChannelId(result.payload.channelId);
          logEvent({
            category: 'Campaign management',
            action: 'Delete channel',
            label: toDeleteChannel.app_route,
          });
        }
      }
    );
    setToDeleteChannel(null);
  }, [campaignId, toDeleteChannel, dispatch, deletedChannelId]);
  const onArchiveChannel = useCallback(() => {
    dispatch(
      archiveChannel({
        campaignId,
        channel: toArchiveChannel,
        archivalStatus: !toArchiveChannel.archived,
      })
    ).then((result) => {
      if (archiveChannel.fulfilled.match(result)) {
        setArchivedChannelInfo({
          id: result.payload.channelId,
          status: !toArchiveChannel.archived,
        });
        logEvent({
          category: 'Campaign management',
          action: 'Archive channel',
          label: toArchiveChannel.app_route,
        });
      }
    });
    setToArchiveChannel(null);
  }, [campaignId, toArchiveChannel, dispatch, archivedChannelInfo]);
  const onFocusChannel = useCallback(
    ({ id }) => dispatch(setFocusChannelId(id)),
    [dispatch]
  );

  const rowFields = useMemo(
    () => [
      enrichFieldProps(
        {
          accessor: (channel) => ({
            name: channel?.name,
            archived: channel?.archived,
          }),
          label: t({ id: 'name' }),
          Cell: ChannelNameCell,
        },
        false
      ),
      ...(isFeedbackCampaign
        ? [
            enrichFieldProps(
              {
                accessor: (channel) => channel?.concept?.id,
                label: t({ id: 'concept' }),
                formatter: conceptLabelFormatter,
                colorFormatter: conceptColorFormatter,
                Cell: LabelCell,
              },
              false
            ),
          ]
        : []),
      ...fields,
      enrichFieldProps(
        {
          accessor: 'create_date',
          label: t({ id: 'creation-date' }),
          width: svars.defaultDateColumnWidth,
        },
        false,
        { isDateTime: true }
      ),
      enrichFieldProps(
        {
          accessor: 'n_feedback',
          sortType: 'basic',
          label: t({ id: 'responses' }),
          Cell: ActivityIndicatorCell,
          width: 80,
          centered: true,
        },
        false
      ),
    ],
    [
      conceptColorFormatter,
      conceptLabelFormatter,
      fields,
      isFeedbackCampaign,
      i18n.locale,
    ]
  );

  const renderItemRow = useMemo(
    () =>
      ManagementListRowFactory(
        setToDeleteChannel,
        rowActions,
        acl?.w,
        null,
        null,
        setToArchiveChannel
      ),
    [rowActions, acl]
  );

  const updateItems = useCallback(
    (items) => {
      let updatedItems = items;
      if (deletedChannelId) {
        updatedItems = updatedItems.filter(
          (item) => item.id !== deletedChannelId
        );
      }
      if (archivedChannelInfo) {
        updatedItems = updatedItems.map((item) =>
          item.id === archivedChannelInfo.id
            ? { ...item, archived: archivedChannelInfo.status }
            : item
        );
      }

      return updatedItems;
    },
    [archivedChannelInfo, deletedChannelId]
  );
  const toDeleteChannelName = toDeleteChannel?.name;
  const toArchiveChannelName = toArchiveChannel?.name;

  return (
    <PageLayout padded>
      <ManagementList
        updateItems={updateItems}
        payloadAccessor="channels"
        testid="bo-channel-search-bar"
        onRowClick={onFocusChannel}
        loading={channelsAreLoading}
        defaultSorted={{
          key: defaultSortField,
          ascending: defaultSortOrder === 1,
        }}
        rowFields={rowFields}
        isRowActive={(item) => item.id === focusChannel?.id}
        nActions={rowActions?.length}
        renderItemRow={renderItemRow}
        textFilterPlaceholder={i18n._(t({ id: 'search-a-channel' }))}
        emptyListHeader={<Trans id="channel-list.no-channel-title" />}
        emptySearchHeader={
          <Trans id="channel-list.no-channel-found-after-search" />
        }
        EmptyListContent={emptyChannelListContentFactory(onOpenCreateModal)}
        itemsToFetch={fetchChannels}
        hasLockedProperty
        actions={
          <AnalyticsAwareButton
            gaCategory="Campaign management"
            gaAction="Create channel"
            icon="add"
            labelPosition="right"
            inputComponent={ButtonAccent}
            onClick={onOpenCreateModal}
            content={<Trans id="create-channel" />}
            data-testid="bo-create-channel-button"
            disabled={!acl?.w}
            disabledHelp={t({ id: 'create-channel-disabled-help' })}
          />
        }
      />
      <DeleteModal
        open={toDeleteChannel !== null}
        onClose={onCloseDeleteModal}
        onDelete={onValidateDelete}
        headerText={<Trans id="channel-deletion.confirm-modal-title" />}
        contentText={
          <Trans>
            channel-deletion.confirm-modal-message-
            <b>{toDeleteChannelName}</b>
          </Trans>
        }
        contentSubText={
          <Trans>
            channel-deletion.confirm-modal-sub-message-
            <DeleteModal.WarningText>delete-forever</DeleteModal.WarningText>
          </Trans>
        }
        confirmWithInputValue={t({ id: 'to-delete-link-and-responses' })}
      />
      <DeleteModal
        open={toArchiveChannel !== null}
        onClose={onCloseArchiveModal}
        onDelete={onArchiveChannel}
        confirmButtonText={
          !toArchiveChannel?.archived
            ? t({ id: 'archive-button' })
            : t({ id: 'unarchive-button' })
        }
        headerText={
          !toArchiveChannel?.archived ? (
            <Trans>
              channel-archive.confirm-modal-title-
              <b>{toArchiveChannelName}</b>
            </Trans>
          ) : (
            <Trans>
              channel-unarchive.confirm-modal-title-
              <b>{toArchiveChannelName}</b>
            </Trans>
          )
        }
        contentText={
          !toArchiveChannel?.archived ? (
            <Trans>
              channel-archive.confirm-modal-message-
              <b>{toArchiveChannelName}</b>
              <DeleteModal.WarningText>
                -unsable-to-new-respondents
              </DeleteModal.WarningText>
            </Trans>
          ) : (
            <Trans>
              channel-unarchive.confirm-modal-message-
              <b>{toArchiveChannelName}</b>
              <DeleteModal.WarningText>
                -usable-to-new-respondents
              </DeleteModal.WarningText>
            </Trans>
          )
        }
      />
      {/* A key is required to reinstantiate component upon campaign change, for hook logic */}
      <ChannelPane key={`chp-${campaignId}`} channel={focusChannel} />
      <QrCodeModal
        onClose={onQrCodeModalClose}
        open={!!qrCodeModalChannel}
        value={formatFeedbackAppRoute(qrCodeModalChannel)}
        channelName={qrCodeModalChannel?.name}
      />
    </PageLayout>
  );
}

CampaignChannelListPage.propTypes = {};

export default CampaignChannelListPage;
